<template>
<div class="administrationpois-container">
    <slideout-panel></slideout-panel>

    <PoiSearch @search="applyFilters" @query="(e) => {applyFilters({search: e})}" :auto="false" :oldfilters="$route.params.oldFilters"/>
    <vs-row justify="flex-end" style="width:100%;">
        <vs-button size="large" border @click="batchGooglePlacesDialog = true">Google Places</vs-button>
        <vs-button size="large" @click="exportPois">Esporta Risultato</vs-button>
        <vs-button size="large" @click="batchPublishDialog = true">Pubblicazione di massa</vs-button>
        <vs-button size="large" @click="createNew">Crea Nuovo</vs-button>
    </vs-row>

    <vs-dialog v-model="batchGooglePlacesDialog">
        <p>Verrà tentata la ricerca di POI vicini utilizzando Google Places API per tutti i risultati che rispecchiano i filtri selezionati.</p>
        <p>Questa operazione potrebbe richiedere diverso tempo.</p>
        <p v-if="batchGooglePlacesMessage != null">{{ batchGooglePlacesMessage }}</p>
        <vs-button size="large" v-if="batchGooglePlacesWaitingConfirm" block @click="batchGooglePlacesConfirm" :loading="batchGooglePlacesLoading"> {{ $t('common.continue') }}</vs-button>
        <vs-button size="large" v-else block @click="batchGooglePlacesStart" :loading="batchGooglePlacesLoading"> {{ $t('common.continue') }}</vs-button>
    </vs-dialog>

    <vs-dialog v-model="batchPublishDialog">
        <p>Lo stato di pubblicazione verrà cambiato per tutti i risultati che rispecchiano i filtri selezionati.</p>
        <p>Questa operazione potrebbe richiedere diverso tempo.</p>
        <p v-if="batchPublishMessage != null">{{ batchPublishMessage }}</p>
        <vs-checkbox block v-model="batchPublishValue">Pubblicato</vs-checkbox>
        <vs-button size="large" v-if="batchPublishWaitingConfirm" block @click="batchPublishConfirm" :loading="batchPublishLoading"> {{ $t('common.continue') }}</vs-button>
        <vs-button size="large" v-else block @click="batchPublishStart" :loading="batchPublishLoading"> {{ $t('common.continue') }}</vs-button>
    </vs-dialog>
    <vs-table v-model="selected" style="height: 80vh; width: 100%; margin-top: 20px;">
        <template #thead>
            <vs-tr>
                <vs-th style="width: 10%;">
                    Target
                </vs-th>
                <vs-th>
                    Name
                </vs-th>
                <vs-th>
                    Street
                </vs-th>
                <vs-th>
                    {{ $t('poi.yearOfConstruction') }}
                </vs-th>
                <vs-th>
                    Published
                </vs-th>
                <vs-th>
                    Google Places
                </vs-th>
            </vs-tr>
        </template>
        <template #tbody>
            <vs-tr :key="i" v-for="(tr, i) in pois" :data="tr" :is-selected="selected == tr">
                <vs-td>
                    <img :src="targets[tr.target_id]" style="width: auto; height: 64px;" />
                </vs-td>
                <vs-td>
                    {{ tr.localized != null ? tr.localized.find(e => e.language_id == user.language.id).name : '-' }}
                </vs-td>
                <vs-td>
                    {{ tr.street }}
                </vs-td>
                <vs-td>
                    {{ tr.year_built }}
                </vs-td>
                <vs-td>
                    {{ tr.published ? 'Yes' : 'No' }}
                </vs-td>

                <vs-td>
                    {{ tr.pictures ? tr.pictures.filter(e => e.description.toLowerCase().includes('google places')).length : 0 }}
                </vs-td>
            </vs-tr>
        </template>
        <template #footer>
            <vs-row justify="center" align="center">
                <vs-button :disabled="result == null || result.before == null" @click="previousPage">
                    <img src="../../assets/icons/previous_page.png" />
                </vs-button>
                <vs-button :disabled="result == null || result.after == null" @click="nextPage">
                    <img src="../../assets/icons/next_page.png" />
                </vs-button>
            </vs-row>

        </template>
    </vs-table>
</div>
</template>

<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
import PoiSearch from '../../components/PoiSearch.vue'
import EditPoi from '../../components/elements/EditPoi.vue';
export default ({
    data() {
        return {
            pois: [],
            selected: null,
            filters: {},
            result: null,
            panel: null,
            baseUri,

            batchPublishDialog: false,
            batchPublishValue: false,
            batchPublishPois: [],
            batchPublishLoading: false,
            batchPublishMessage: null,
            batchPublishWaitingConfirm: false,

            batchGooglePlacesDialog: false,
            batchGooglePlacesLoading: false,
            batchGooglePlacesWaitingConfirm: false,
            batchGooglePlacesMessage: null,
            batchGooglePlacesNearbies: [],


        }
    },
    props: {
        user: {
            type: Object,
            required: false
        }
    },

    computed: {
        targets(){
            return this.$TARGETS.icons;
        }
    },

    mounted() {
        if(this.$route.params.oldFilters){ // if route params oldFilters is set, use it
            console.log(this.$route.params.oldFilters)
            this.filters = this.$route.params.oldFilters; // this will trigger searchPois, we dont want to trigger twice
        } else {
            this.searchPois(this.filters)
        }

    },

    methods: {
        createNew() {
            this.selected = {
                id: null,
                city_id: null,
                target_id: null,
                place_to_discover: false,
                year_built: '',
                author: '',
                website: '',
                point: {
                    x: 0,
                    y: 0
                },
                street: '',
                published: false,
                poi_localization_infos: []
            }
        },
        previousPage() {
            this.filters.before = this.result.before;
            this.filters.after = null;
            this.filters.__ob__.dep.notify();
        },

        nextPage() {
            this.filters.after = this.result.after;
            this.filters.before = null;
            this.filters.__ob__.dep.notify();
        },
        applyFilters(filters) {
            console.log(filters)
            Object.assign(this.filters, filters)
            this.filters.before = null;
            this.filters.after = null;
            this.filters.__ob__.dep.notify();
        },

        deleted() {
            this.searchPois(this.filters)
        },

        async getPoiDetail(id) {
            // use apiCall to make a request to /pois
            const response = await apiCall('GET', '/backoffice/pois', {id});
            // if status code is 200, show a success message 
            if (response.status == 200) {
                
                if (response.data.data != null && response.data.data.length > 0) {
                    var pois = response.data.data.map(e => ({
                        ...e,
                        point: {
                            x: e.longitude,
                            y: e.latitude
                        },
                        poi_localization_infos: e.localized
                    }));
                    return pois[0];
                } else {
                    return null;
                }
            }
            return null;
        },

        async searchPois(p) {
            this.selected = null;
            const loading = this.$vs.loading();
            // use apiCall to make a request to /pois
            const response = await apiCall('GET', '/backoffice/pois', p);
            loading.close();
            // if status code is 200, show a success message 
            if (response.status == 200) {
                console.log(response.data.data)
                if (response.data.data != null && response.data.data.length > 0) {
                    this.result = response.data;
                    this.pois = response.data.data.map(e => ({
                        ...e,
                        point: {
                            x: e.longitude,
                            y: e.latitude
                        },
                        poi_localization_infos: e.localized
                    }));

                    // if route params oldPoi is set, select it
                    if(this.$route.params.oldPoi){
                        this.selected = this.pois.find(e => e.id == this.$route.params.oldPoi.id);
                        this.$route.params.oldPoi = null; // avoid double selection
                    }
                } else {
                    this.pois = []
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.messages.unableToLoadPOIs'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async batchPublishStart() {
            this.batchPublishWaitingConfirm = false;
            this.batchPublishLoading = true;
            this.batchPublishMessage = null;
            await this.getAllPois(this.filters);
            this.batchPublishMessage = 'Verranno modificati ' + this.batchPublishPois.length + ' POI, vuoi proseguire?';
            this.batchPublishWaitingConfirm = true;
            this.batchPublishLoading = false;

        },

        async batchPublishConfirm() {
            this.batchPublishLoading = true;
            this.batchPublishPois = this.batchPublishPois.map(e => ({
                ...e,
                published: this.batchPublishValue
            }));
            var s = true;
            for (var i = 0; i < this.batchPublishPois.length; i++) {
                this.batchPublishMessage = 'Pubblicazione ' + (i + 1) + ' di ' + this.batchPublishPois.length + ' POI';
                if (!await this.editPoi(this.batchPublishPois[i], true)) {
                    s = false;
                    this.batchPublishMessage = 'Errore durante la pubblicazione di ' + (i + 1) + ' di ' + this.batchPublishPois.length + ' POI';
                    break;
                }
            }
            if (s) {
                this.batchPublishMessage = 'Pubblicazione di ' + this.batchPublishPois.length + ' POI completata';
            }

            await this.searchPois(this.filters);
            this.batchPublishLoading = false;
        },

        async nearbySearch(data) {
            const loading = this.$vs.loading({
                shape: 'square',
                text: 'Connecting to Google Places API...',
            });

            

            const response = await apiCall('GET', '/backoffice/nearby', {
                latitude: data.lat,
                longitude: data.lng,
                primary_types: ['tourist_attraction'],
            });

            if(response.status == 200 && response.data && response.data.places && response.data.places.length){
                loading.close();
                return response.data.places;
            }
            loading.close();
            return [];
        },


        async batchGooglePlacesStart() {
            this.batchGooglePlacesLoading = true;
            this.batchGooglePlacesMessage = null;
            this.batchGooglePlacesWaitingConfirm = false;
            await this.getAllPois(this.filters);
            this.batchGooglePlacesMessage = 'Verranno cercati POI vicini per ' + this.batchPublishPois.length + ' POI, vuoi proseguire?';
            this.batchGooglePlacesWaitingConfirm = true;
            this.batchGooglePlacesLoading = false;

        },

        async batchGooglePlacesConfirm() {
            this.batchGooglePlacesLoading = true;
            this.batchGooglePlacesNearbies = [];
            var poisFound = 0;
            for (var i = 0; i < this.batchPublishPois.length; i++) {
                this.batchGooglePlacesMessage = 'Ricerca POI vicini per ' + (i + 1) + ' di ' + this.batchPublishPois.length + ' POI';

                let result = await this.nearbySearch({
                    lat: this.batchPublishPois[i].point.y,
                    lng: this.batchPublishPois[i].point.x,
                    target_ids: [this.batchPublishPois[i].target_id]
                });

                if(result.length > 0){
                    poisFound++;

                    result = result.map(e => ({
                        ...e,
                        poi: this.batchPublishPois[i],
                        localized_name: this.batchPublishPois[i].localized.find(e => e.language_id == this.user.language.id).name
                    }));
                }

                this.batchGooglePlacesNearbies = this.batchGooglePlacesNearbies.concat(result);
            }

            // how many founds
            this.batchGooglePlacesMessage = 'Trovati ' + poisFound + ' POI vicini';
            this.batchGooglePlacesLoading = false;

            this.$router.push({
                name: 'ADM_GooglePlaces',
                params: {
                    inputPlaces: this.batchGooglePlacesNearbies,

                    poisAdminFilters: this.filters,
                }
            })
        },

        async getAllPois(p) {
            if (!this.batchPublishDialog && !this.batchGooglePlacesDialog) {
                return;
            }
            // that match filters
            this.selected = null;
            // use apiCall to make a request to /pois
            const response = await apiCall('GET', '/backoffice/pois', p);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                console.log(response.data.data)
                if (response.data.data != null && response.data.data.length > 0) {
                    this.batchPublishPois = this.batchPublishPois.concat(response.data.data.map(e => ({
                        ...e,
                        point: {
                            x: e.longitude,
                            y: e.latitude
                        },
                        poi_localization_infos: e.localized
                    })));
                    this.batchPublishMessage = 'Pubblicazione 0 di ' + this.batchPublishPois.length + ' POI';
                    this.batchGooglePlacesMessage = 'Ricerca POI vicini 0 di ' + this.batchPublishPois.length + ' POI';
                }

                if (response.data.after != null) {
                    return await this.getAllPois({
                        ...p,
                        after: response.data.after
                    })
                } else {
                    return this.batchPublishPois;
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.messages.unableToLoadPOIs'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async exportPois() {
            const loading = this.$vs.loading();

            const response = await apiCall('GET', '/backoffice/pois/excel', this.filters, false, 'text/plain', true);
            loading.close();
            // if status code is 200, show a success message 
            if (response.status == 200) {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                anchorElement.download = 'tourial_export_' + (new Date()).toISOString() + '.csv';

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('explore.messages.unableToLoadPOIs'),
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async editPoi(poi, silent = false) {
            // use apiCall to make a request to /pois
            poi.localized = poi.poi_localization_infos;
            const response = await apiCall(poi.id == null ? 'POST' : 'PATCH', '/backoffice/pois' + (poi.id != null ? ('/' + poi.id) : ''), poi);
            // if status code is 200, show a success message 
            if (response.status == 200) {
                if (poi.id == null) { // ho fatto la creazione
                    this.selected.id = response.data.id;
                    // necessario riottenere tutte le informazioni complete
                    this.selected.poi_localization_infos = (await this.getPoiDetail(response.data.id)).localized;
                }
                if (!silent) {
                    this.$vs.notification({
                        title: this.$t('common.messages.success'),
                        text: 'POI modificato con successo',
                        color: 'success',
                        position: 'top-right'
                    });
                }
                return true;
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile modificare il POI',
                    color: 'danger',
                    position: 'top-right'
                });

            }
            return false;
        }
    },

    watch: {
        filters(n) {
            this.searchPois(n);
        },
        async selected(n) {

            var vm = this;

            if (this.panel != null) {
                await this.panel.hide();
                this.panel = null;
            }

            if (n == null) {
                return;
            }
            this.panel = await this.$showPanel({
                component: EditPoi,
                openOn: 'right',
                props: {
                    poi: n,
                    editPoi: this.editPoi,
                    deleted: this.deleted,
                    user: this.user,
                    adminFilters: this.filters // pass filters to the panel, in this way if it goes to googleplaces trough button we can add "go back" button to return to the same filters
                }
            });

            this.panel.promise
                .then(() => {
                    vm.selected = null;
                });

        },

        batchPublishDialog() {
            this.batchPublishValue = false;
            this.batchPublishPois = [];
            this.batchPublishMessage = null;
            this.batchPublishWaitingConfirm = false;
        },
    },

    components: {
        PoiSearch,
    }
})
</script>

<style scoped>
.administrationpois-container {
    padding-left: 70px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
}
</style>
